import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactoContent from "../components/ContactoContent"
import Contact from "../components/Contact"

import '../styles/index.css'

const Gracias = ({ data, location }) => {
  return (
    <Layout pathname={location?.pathname}>
      <SEO title="Gracias" />
      <div className="sidebar sidebar--left sidebar--only-desktop">
        <Contact key="gracias-contact" image="nosotros-large.png" hideAddress />
      </div>
      <ContactoContent isSuccess data={data?.allPrismicContacto?.edges?.[0]?.node?.data} />
      <div className="sidebar sidebar--right sidebar--only-desktop">
        &nbsp;
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicContacto {
      edges {
        node {
          data {
            contact {
              contact_description {
                html
                text
                raw
              }
              contact_title {
                html
                text
                raw
              }
            }
            contact_form {
              html
              raw
              text
            }
            contact_success {
              html
              raw
              text
            }
          }
        }
      }
    }
  }
`

export default Gracias
